.card__box {
  border: 2px solid #212121;
  border-top: 2px solid #21cdc0  !important;
}


.card__container__header{
    color: #1B2C57;
}
 
.card__container__desc{
    color: #848E9F;
    font-weight: 400;
}

.card__btn{
  margin-right: 10px;
}

.card__box{
  border-bottom: 2px solid #1B2C57;
}