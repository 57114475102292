.slider {
  position: relative;
}


.appoinment__container{
  background-color: #2DCCC0;
}

.header__container{
  margin: 0;
  padding: 10px;
  background-color: #2D4990;
  position: relative;
}

.header__container li{
  list-style-type: none;
  color: white;
}

.header__iconColor{
  color: #2DCCC0;
}

.header__social li{
  padding-right: 10px;
}

@media only screen and (max-width: 956px) {
  .header__main{
    display: none;
  }
}

.footer-line{
  border: 4px solid #2DCCC0;
  margin: 0;
}