* {
  text-align: justify;
  margin: 0;
  scroll-behavior: smooth;
}
a,
u {
  text-decoration: none !important;
}

.text-algin-start{
  text-align: start !important;
}

.color-1 {
  color: #2c488f;
}

.color-2 {
  color: #21cdc0;
}

.color-3 {
  color: #848e9f;
}

.color-4 {
  color: #36D1C6;
}
.no-list-style {
  list-style-type: none;
}

.center-div {
  margin: auto;
  width: 50%;
}

.list-padding li {
  padding-right: 20px;
}

.testimonial-uniform-image {
  float: left;
  width: 200px;
  height: 200px;
  background-size: cover;
}

.testimonial-font {
  font-size: 19px;
  font-weight: 400;
  text-align: start !important;
}

.aboutus-image {
  float: left;
  width: 200px;
  height: 200px;
  background-size: cover;
}

.dummy-btn {
  color: white;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #21cdc0 !important;
  border: 0px !important;
  border-radius: 20px !important;
}

.aboutus-follow {
  display: flex;
  align-items: center;
}

.about-client {
  width: 100px;
  height: 100px;
  padding: 20px;
}

.btn-outline-secondary {
  border-radius: 10px !important;
  border-color: #1b2c57;
  color: #1b2c57;
}

.btn-outline-secondary:focus {
  background-color: #21cdc0 !important;
  border: none !important;
  border-radius: 10px !important;
}

.btn-primary {
  background-color: #21cdc0 !important;
  border: 0px !important;
  border-radius: 20px !important;
}

.btn-primary:active {
  border: 0px !important;
  box-shadow: none !important;
}

.btn-primary:active:focus {
  color: #ffffff;
  background-color: #161617;
  border-color: #494f57;
}

.btn-secondary {
  background-color: #2c488f !important;
}

ol.carousel-indicators li {
  background: #494f57;
  height: 0.1px !important;
}
ol.carousel-indicators li.active {
  background: #494f57;
  height: 0.1px !important;
}