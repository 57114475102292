.bg-img {
    width: 100%;
    height: 100%;
    background-position: center;
  }
  
  .color-5{
      color:#DEF8F6
  }
  .card-background{
      background-color: #DEF8F6 !important;
      border:0px !important;
      border-radius: 10px !important;
  }