.box_container {
  position: relative;
  top: -80px;
  z-index: 10;
}

.box-1 {
  height: 16em;
  background-color: #21cdc0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.box1-text {
  color: white;
}
.box-2 {
  height: 16em;
  background-color: #3851a2;
}
.box-3 {
  height: 16em;
  background-color: #2d4990;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  
}
.box-3-links:hover{
  background-color:#21cdc0;
  padding: 4px; 
  border-radius: 8px;
}
.box3-marquee{
  height: 5em;
}

.box-3-padding{
    position: relative;
    top:30px;
}

@media only screen and (max-width: 576px) {
  .box_container {
    position: relative;
    top: 0px;
    z-index: 10;
  }

  .box-1 {
    height: 16em;
    background-color: #21cdc0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .box-2 {
    height: 16em;
    background-color: #3851a2;
  }
  .box-3 {
    height: 16em;
    background-color: #2d4990;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    
  }

}

.headerbox__image {
  width: 45%;
  height: 45%;
}
