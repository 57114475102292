.sliderbg {
  background: linear-gradient(0deg, #00000088 30%, #ffffff44 100%);
}

.slidercontainer{

}


@media only screen and (max-width: 576px){
  .slidercontainer{
      height:30em;
  }

  .slideritem{
    height:30em;
    object-fit: cover;
  }
  .slider__caption{
    font-size:2em;
  }
}