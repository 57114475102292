.footer__main{
    position: fixed;
    bottom: 0;
}

.footer__container{
    background-color: #1D2A4D;
    padding: 5rem;
    
}

@media only screen and (max-width: 576px) {
    .footer__container{
        background-color: #1D2A4D;
        padding: 3rem;
        
    }
}


.footer__heading{
    text-transform: capitalize;
    color: white;
}

.footer__about__content{
    text-align: justify;
    color: rgb(158, 154, 154);
    font-weight: 400;
}

.footer__links{
    list-style-type: none;
    color: rgb(158, 154, 154);
}

a{
text-decoration: none;
}

.footer__card{
    width: 24rem;
}
