.changebackground{
    background-color:#2c488f ;
}

.content {
    height: 100%;
  }

.row-flex{
    display: flex;
    flex-wrap: wrap;
}