.scrollbutton {
  position: fixed;
  width: 100%;
  left: 95%;
  bottom: 40px;
  font-size: 3rem;
  background-color: transparent;
  border: transparent;
  z-index: 5;
  cursor: pointer;
  color: #2dccc0;
}

.scrollbutton__btn {
  background-color: white;
  border-radius: 100%;
}
@media (max-width: 320px) {
    .scrollbutton{
        left: 80%;
    }
}
@media (max-width: 480px) {
    .scrollbutton{
        left: 80%;
    }
}
@media (min-width: 600px) {

}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
