.navbar-nav {
  margin-left: auto;
}

.navbar__image {
  width: 150px;
}



.nav-link{
  border:2px solid transparent;
}

.nav-link:hover{
  border-top: 2px solid #21cdc0 ;
  transition: 1s;
}

.header_container{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}

@media (min-width: 768px) and (max-width: 1024px){
  .header_container {
      font-size: 14px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .header_container {
    font-size: 14px;
}
}